<template>
  <div class="shoppingMind">
    <div class="shoppingTop">
      <el-carousel class="shoppingTop" indicator-position="outside">
        <el-carousel-item class="shoppingTop" v-for="i in imgList" :key="i.id">
          <img :src="i.url" class="shoppingImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="shoppingRecom">
      <div class="shoppingRecomTop">Selected recommendations</div>
      <div class="shoppingRecomTitle">精选推荐</div>
      <div class="shoppingRecomTopTu">
        <div class="shoppingRecomTopTuLeft">
          <div class="shoppingRecomTopTuLeftTop">
            爱牵挂生命体征（睡眠）监测仪
          </div>
          <div class="shoppingRecomTopTuLeftConten">
            采用高灵敏压力传感器,通过WIFI通讯技术实时传输,精准监测生命体征及睡眠状
            况数据远程传输
          </div>
          <div class="shoppingRecomTopTuLeftTop">￥30</div>
          <div class="shoppingRecomTopTuLeftBtnCard">
            <div class="shoppingRecomTopTuLeftBtn" @click="detailsClick">查看详情</div>
          </div>
        </div>
        <div class="shoppingRecomTopTuRight">
          <img
            src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111103052_tui1.png"
            class="shoppingRecomTopTuRightImg"
          />
        </div>
      </div>
      <div class="shoppingRecomTuRow">
        <div class="shoppingRecomTuRowLeft">
          <div class="shoppingRecomTopTuLeft">
            <div class="shoppingRecomTopTuLeftTop">报警按钮(手环) HA01</div>
            <div class="shoppingRecomTuRowConten">
              建议长者佩戴处于居室内(主机信号覆盖范围内) 蓝牙5.0连接报警主机
              纽扣电池，待机3年 高等级防水防尘结构设计
            </div>
            <div class="shoppingRecomTopTuLeftTop">￥30</div>
            <div class="shoppingRecomTopTuLeftBtnCard">
              <div class="shoppingRecomTopTuLeftBtn" @click="detailsClick">查看详情</div>
            </div>
          </div>
          <div class="shoppingRecomTopTuRight">
            <img
              src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111103812_tui2.png"
              class="shoppingRecomTopTuRightImg"
            />
          </div>
        </div>
        <div class="shoppingRecomTuRowLeft">
          <div class="shoppingRecomTopTuLeft">
            <div class="shoppingRecomTopTuLeftTop">报警按钮(墙贴) HA0</div>
            <div class="shoppingRecomTuRowConten">
              建议长者佩戴处于居室内(主机信号覆盖范围内) 蓝牙5.0连接报警主机
              纽扣电池，待机
            </div>
            <div class="shoppingRecomTopTuLeftTop">￥30</div>
            <div class="shoppingRecomTopTuLeftBtnCard">
              <div class="shoppingRecomTopTuLeftBtn" @click="detailsClick">查看详情</div>
            </div>
          </div>
          <div class="shoppingRecomTopTuRight">
            <img
              src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111103926_tui3.png"
              class="shoppingRecomTopTuRightImg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="shoppingProduct">
      <div class="shoppingProductTop">Product Classification</div>
      <div class="shoppingProductTitle">产品分类</div>
      <div class="shoppingProductRow">
        <div
          class="shoppingProductRowItem"
          :class="productId == i.id ? 'shoppingProductRowItemActive' : ''"
          v-for="i in productList"
          :key="i.id"
          @click="productClick(i)"
        >
          {{ i.name }}
        </div>
      </div>
      <div class="productBody">
        <!-- 商品循环 -->
        <div class="productCard">
          <div class="productCardTop">
            <img src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111112410_shopping.png" 
            class="productCardTopImg">
          </div>
          <div class="productCardconten">
            <div class="productCardcontenOne">报警按钮(手环) HA01</div>
            <div class="productCardcontenOne">￥30</div>
            <div class="productCardcontenFoot">
              <div class="productCardcontenFootBtn" @click="detailsClick">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          id: 0,
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111135109_comm.png",
        },
      ],
      productList: [
        { id: 0, name: "全部" },
        { id: 1, name: "检测类" },
        { id: 2, name: "收银类" },
        { id: 3, name: "智能手表" },
        { id: 4, name: "软件类" },
      ],
      productId: 0,
    };
  },
  created(){},
  methods:{
    productClick(w){
      this.productId = w.id
    },
    detailsClick(){
      this.$router.push({path:'/details'})
    }
  }
};
</script>

<style>
.shoppingMind {
  width: 100%;
  height: 100%;
}

.shoppingTop {
  width: 100%;
  height: 800px;
}

.el-carousel__container {
  width: 100%;
  height: 760px;
}

.shoppingImg {
  width: 100%;
  height: 100%;
}

.shoppingRecom {
  width: 100%;
  height: 1000px;
  padding: 40px 18%;
  box-sizing: border-box;
  background-color: #f5f7f9;
}

.shoppingRecomTop {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #c9c9c9;
  font-size: 40px;
}

.shoppingRecomTitle {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #033333;
  font-size: 46px;
}

.shoppingRecomTopTu {
  width: 100%;
  height: 320px;
  margin-top: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;
}

.shoppingRecomTopTuLeft {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shoppingRecomTopTuLeftTop {
  width: 100%;
  height: 30px;
  line-height: 30px;
  color: #0c0c0c;
  font-size: 20px;
}

.shoppingRecomTopTuLeftConten {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  color: #033333;
  font-size: 16px;
}

.shoppingRecomTopTuLeftBtnCard {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.shoppingRecomTopTuLeftBtn {
  width: 110px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  border: 1px solid #2680eb;
  color: #1764fc;
  font-size: 16px;
}

.shoppingRecomTopTuLeftBtn:hover{
  cursor: pointer;
}

.shoppingRecomTopTuRight {
  width: 50%;
  height: 100%;
}

.shoppingRecomTopTuRightImg {
  width: 100%;
  height: 100%;
}

.shoppingRecomTuRow {
  width: 100%;
  height: 360px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.shoppingRecomTuRowLeft {
  width: 588px;
  height: 100%;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.shoppingRecomTuRowConten {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 16px;
}

.shoppingProduct {
  width: 100%;
  min-height: 800px;
  background-color: #f5f7f9;
  padding: 20px 18%;
  box-sizing: border-box;
}

.shoppingProductTop {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 40px;
  color: #c9c9c9;
}

.shoppingProductTitle {
  width: 100%;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 46px;
  color: #033333;
}

.shoppingProductRow {
  width: 100%;
  height: 60px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.shoppingProductRowItem {
  width: 140px;
  height: 60px;
  line-height: 60px;
  color: #033333;
  font-size: 20px;
}

.shoppingProductRowItem:hover{
  cursor: pointer;
}

.shoppingProductRowItemActive {
  color: #1764fc;
}

.productBody{
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.productCard{
  width: 280px;
  height: 380px;
  background-color: white;
  margin-top: 20px;
  margin-right: 20px;
  padding: 15px;
  box-sizing: border-box;
}

.productCardTop{
  width: 100%;
  height: 190px;
  padding: 15px;
  box-sizing: border-box;
}

.productCardTopImg{
  width: 100%;
  height: 100%;
}

.productCardconten{
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productCardcontenOne{
  width: 100%;
  height: 30px;
  color: #0C0C0C;
  line-height: 30px;
  font-size: 20px;
}

.productCardcontenFoot{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.productCardcontenFootBtn{
  width: 110px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  border: 1px solid #2680EB;
  color: #1764FC;
  font-size: 16px;
}

.productCardcontenFootBtn:hover{
  cursor: pointer;
}
</style>